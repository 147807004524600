<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ApiClass from "../../api/api";
// import Swal from "sweetalert2";

/**
 * Lending component
 */
export default {
  page: {
    title: "Token Request List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Token Request List",
      items: [
        {
          text: "Token Request",
          href: "/admin",
        },
        {
          text: "Token Request List",
          active: true,
        },
      ],
      loading: true,
      fields: [
        "Index",
        {
          key: "name",
          value: "name",
        },
        {
          key: "email",
          value: "email",
        },
        {
          key: "mobile",
          value: "mobile",
        },
      ],
    };
  },
  mounted() {
    this.myProvider();
  },
  methods: {
    async myProvider() {
      var res = await ApiClass.getRequest("admin/request/get", true);
      if (res.data.status_code == 1) {
        this.loading = false;
        return res.data.data;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Token Request List</h4>

            <div v-if="!loading">
              <b-table
                striped
                hover
                :items="myProvider"
                :fields="fields"
                :busy="loading"
                ref="table"
                show-empty
                sortable="false"
              >
                <template #empty>
                  <div class="d-flex justify-content-center mb-3">
                    No Record Found
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <!-- <strong>Loading...</strong> -->
                  </div>
                </template>

                <!-- A virtual column -->
                <template #cell(Index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(name)="data">
                  <div>
                    {{ data.item.name }}
                  </div>
                </template>

                <template v-slot:cell(email)="data">
                  <div>
                    {{ data.item.email }}
                  </div>
                </template>

                <template v-slot:cell(mobile)="data">
                  <div>
                    {{ data.item.mobile }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>


<style scoped>
.front_image img,
.back_image img {
  width: 150px;
  height: 120px;
}
.succ_btn {
  float: right;
  clear: both;
  margin: 20px 0px;
}
.clr-red {
  color: red;
}
.success {
  color: green;
  font-weight: 600;
  font-size: 14px;
}
</style>